import Vue from "vue";
import VueI18n, { LocaleMessages } from "vue-i18n";
import { mergeDeep } from "./lib/utils";

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
  const ownLocales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );

  const patternslocales = require.context(
    "@pasabi/ui-patterns/dist/locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );

  const chartslocales = require.context(
    "@pasabi/ui-charts/dist/locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );

  const messages: LocaleMessages = {};

  [patternslocales, chartslocales, ownLocales].forEach((locale) => {
    locale.keys().forEach((key) => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i);
      if (matched && matched.length > 1) {
        const match = matched[1];
        messages[match] = mergeDeep(messages[match] || {}, locale(key));
      }
    });
  });

  return messages;
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages(),
});

/**
 * Dynamic Keys
 * i18n.t("marketplace.state.category_page")
 * i18n.t("marketplace.state.product_found")
 * i18n.t("marketplace.state.product_available")
 * i18n.t("marketplace.state.product_not_available")
 * i18n.t("marketplace.state.product_added_to_basket")
 * i18n.t("marketplace.state.product_not_added_to_basket")
 * i18n.t("marketplace.state.not_a_product_page")
 * i18n.t("marketplace.state.no_page_text")
 * i18n.t("marketplace.state.purchasable")
 * i18n.t("marketplace.state.not_purchasable")
 * i18n.t("marketplace.state.purchasable_bypass")
 *
 * i18n.t("filters.metadata.status.category_page")
 * i18n.t("filters.metadata.status.product_found")
 * i18n.t("filters.metadata.status.product_available")
 * i18n.t("filters.metadata.status.product_not_available")
 * i18n.t("filters.metadata.status.product_added_to_basket")
 * i18n.t("filters.metadata.status.product_not_added_to_basket")
 * i18n.t("filters.metadata.status.not_a_product_page")
 * i18n.t("filters.metadata.status.no_page_text")
 * i18n.t("filters.metadata.purchasable_status.purchasable")
 * i18n.t("filters.metadata.purchasable_status.not_purchasable")
 * i18n.t("filters.metadata.purchasable_status.purchasable_bypass")
 * i18n.t("filters.metadata.purchasable_status.not_a_product_page")

 * i18n.t("filters.channel.amazon")
 * i18n.t("filters.channel.ebay")
 * i18n.t("filters.channel.google")
 * i18n.t("filters.channel.tmall")
 * i18n.t("filters.channel.naver")
 * i18n.t("filters.channel.blibli")
 * i18n.t("filters.channel.lazada")
 * i18n.t("filters.channel.tokopedia")
 * i18n.t("filters.channel.carousell")
 * i18n.t("filters.channel.farfetch")
 * i18n.t("filters.channel.facebook_marketplace")
 * i18n.t("filters.channel.farfetch")
 * i18n.t("filters.channel.shopee")
 * i18n.t("filters.channel.mercado_libre")
 * i18n.t("filters.channel.jd")
 * i18n.t("filters.channel.instagram")
 * i18n.t("filters.channel.miinto")
 *
 * i18n.t("filters.catalogue_info.purpose.seller")
 * i18n.t("filters.catalogue_info.purpose.product")
 * i18n.t("filters.catalogue_info.purpose.generic")
 *
 * i18n.t("filters.title.name")
 * i18n.t("filters.title.date_created")
 * i18n.t("filters.title.date_modified")
 * i18n.t("filters.title.date_first_seen")
 * i18n.t("filters.title.channel")
 * i18n.t("filters.title.recent")
 * i18n.t("filters.title.metadata.status")
 * i18n.t("filters.title.metadata.purchasable_status")
 * i18n.t("filters.title.catalogue_info.search_term")
 * i18n.t("filters.title.catalogue_info.country_code")
 * i18n.t("filters.title.catalogue_info.purpose")
 * i18n.t("filters.title.solved_state.current.date")
 * i18n.t("filters.title.solved_state.current.value")
 * i18n.t("filters.title.flags.date_created")
 * i18n.t("filters.solved_state.current.value.solved")
 * i18n.t("filters.solved_state.current.value.not-solved")
 * i18n.t("filters.title.scores.relevant.score_1y")
 * i18n.t("filters.title.scores.relevant.score_3mo")
 *
 * i18n.t("filters.title.seller._id")
 * i18n.t("filters.title.seller.name")
 *
 * i18n.t("marketplace.screenshot.product_page")
 * i18n.t("marketplace.screenshot.cart_after")
 * i18n.t("marketplace.screenshot.cart_before")
 * i18n.t("marketplace.screenshot.step_four")
 * i18n.t("marketplace.screenshot.step_three")
 * i18n.t("marketplace.screenshot.step_two")
 * i18n.t("marketplace.screenshot.step_one")
 * i18n.t("marketplace.screenshot.step_two_three_comparison")
 * i18n.t("marketplace.screenshot.step_two_four_comparison")
 * i18n.t("marketplace.screenshot.step_three_four_comparison")
 *
 * i18n.t("sellers.details.overview.productsOverTime.count")
 * i18n.t("sellers.details.overview.productsOverTime.seller")
 * i18n.t("sellers.details.overview.productsOverTime.product")
 * i18n.t("sellers.details.overview.productsOverTime.generic")
 *
 */
