import store from "..";
import { Module, getModule } from "vuex-module-decorators";
import {
  DetailsPageStore,
  IDetailsStoreAssociations,
} from "@pasabi/ui-patterns";
import { $api } from "../../api/api";
import { Product } from "../../types";

@Module({
  name: "ProductDetails",
  store: store,

  dynamic: true,
  namespaced: true,
})
class ProductDetails extends DetailsPageStore<Product> {
  _apiPath = "/products";
  _associations: IDetailsStoreAssociations = {
    activities: {
      _apiPath: () => `/activities`,
    },
    solve_state: {
      _apiPath: "solve",
    },
  };
  get api() {
    return $api;
  }

  get productName() {
    return (productId: string) => {
      const product = this.item(productId);

      if (product) {
        return product.title;
      }
      return "";
    };
  }
}

export const ProductDetailsStore = getModule(ProductDetails);
