import store from "..";
import { Module, getModule } from "vuex-module-decorators";
import {
  DetailsPageStore,
  IDetailsStoreAssociations,
} from "@pasabi/ui-patterns";
import { $api } from "../../api/api";
import { Seller } from "../../types";

@Module({
  name: "SellerDetails",
  store: store,
  dynamic: true,
  namespaced: true,
})
class SellerDetails extends DetailsPageStore<Seller> {
  _apiPath = "/sellers";

  _associations: IDetailsStoreAssociations = {
    overview: {
      _apiPath: "overview",
    },
    productsOverTime: {
      _apiPath: "activity",
    },
    tagsBreakdown: {
      _apiPath: ({ id }) => `stats/tags/group/sellers/${id}`,
    },
    products: {
      _apiPath: ({ id }) => `sellers/${id}/products`,
    },
    customerFields: {
      _apiPath: ({ id }) => `ccf/sellers/${id}`,
    },
    activities: {
      _apiPath: () => `/activities`,
    },
  };

  get api() {
    return $api;
  }

  get sellerName() {
    return (sellerId: string) => {
      const seller = this.item(sellerId);

      if (seller) {
        return seller.name;
      }
      return "";
    };
  }
}

export const SellerDetailsStore = getModule(SellerDetails);
