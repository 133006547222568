import router from "../../router";
import store from "..";
import { Action, Module, getModule } from "vuex-module-decorators";
import { Group, GroupsStore as BaseGroupsStore } from "@pasabi/ui-patterns";
import { AuthStore } from "./Auth";
import { $api } from "../../api/api";
import { WorkspacesStore } from "./Workspaces";

@Module({
  name: "Groups",
  store: store,
  namespaced: true,
  dynamic: true,
})
class GroupsModule extends BaseGroupsStore {
  get api() {
    return $api;
  }

  get groupsList(): Group[] {
    if (this.items.length) {
      return this.items;
    } else {
      return Object.values(
        AuthStore.userPermissions().reduce(
          (acc, cur) => ({ ...acc, [cur.group_id]: cur.group }),
          {}
        )
      );
    }
  }

  get currentGroup(): Group | undefined {
    return this.groupsList.find((w) => w._id == this.currentGroupId);
  }

  get groupById(): (id: string) => Group | undefined {
    return (id: string) => {
      return this.groupsList.find((w) => w._id == id);
    };
  }

  @Action
  async redirectToGroup(group: Group) {
    if (router.currentRoute.params.groupId !== group._id) {
      const curMatchesGroup = router.currentRoute.matched.some(
        (r) => r.meta.groupContext
      );

      router.push(
        curMatchesGroup
          ? {
              params: {
                ...router.currentRoute.params,
                groupId: group._id,
              },
            }
          : {
              name: "GroupHome",
              params: {
                groupId: group._id,
              },
            }
      );
    }
  }

  @Action
  async setGroupById({
    groupId,
    redirect = false,
  }: {
    groupId: string;
    redirect: boolean;
  }) {
    // check if groups were already loaded into store
    if (!this.groupsList.length) {
      await this.context.dispatch("loadGroups");
    }

    const group = this.groupById(groupId);
    if (group) {
      this.context.dispatch("setCurrentGroupId", group._id);
      WorkspacesStore.setCurrentWorkspaceId("");

      if (redirect) {
        this.context.dispatch("redirectToGroup", group);
      }
    } else {
      console.error(`Invalid group selected (${groupId})`);
      router.push({
        name: "Home",
      });
    }
  }
}
export const GroupsStore = getModule(GroupsModule);
