<template>
  <div id="app" class="app">
    <router-view />
    <portal-target name="body"></portal-target>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { PortalTarget } from "portal-vue";

@Component({
  components: {
    PortalTarget,
  },
})
export default class App extends Vue {}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
