import "medium-editor/dist/css/medium-editor.css";
import "@pasabi/ui-charts/dist/css/ui-charts.css";
import "@pasabi/ui-components/dist/css/ui-components.css";
import "@pasabi/ui-patterns/dist/css/ui-patterns.css";
import "@/assets/scss/common.scss";
import Vue from "vue";
import VuePortal from "portal-vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import UiPatterns from "@pasabi/ui-patterns";
import UiAnalytics from "@pasabi/ui-analytics";
import { WorkspacesStore } from "./store/modules/Workspaces";
import { GroupsStore } from "./store/modules/Groups";
import { AccountStore } from "./store/modules/Account";

Vue.config.productionTip = false;

Vue.use(VuePortal);
Vue.use(UiPatterns);
Vue.use(UiAnalytics, {
  id: process.env.VUE_APP_GTM_ID || "GTM-UNDEFINED",
  debug: process.env.NODE_ENV !== "production",
  router: router,
  routerAdditionalEventData: () => {
    return {
      workspace_id: WorkspacesStore.currentWorkspace?._id,
      workspace_slug: WorkspacesStore.currentWorkspace?.slug,
      group_id: GroupsStore.currentGroup?._id,
      group_name: GroupsStore.currentGroup?.name,
      org_id: GroupsStore.currentGroup?.org_id,
      user_id: AccountStore.loggerUser?._id,
    };
  },
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
